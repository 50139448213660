












































































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppToggleVisibility from "@/components/core/AppToggleVisibility.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheLogoWithText from "@/components/TheLogoWithText.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";
import { createErrorNotification, Notification } from "@/utils/notification";
import { authenticate, logout } from "@/utils/authentication";

@Component({
  components: {
    AppInputGroup,
    AppInput,
    AppLabel,
    AppButton,
    AppToggleVisibility,
    AppWidget,
    TheLogoWithText,
    TheVerticalCenter,
  },
})
export default class Login extends Vue {
  user: pro.User = {};
  notification: Notification | null = null;
  loading = false;
  showPassword = false;

  get logout(): boolean {
    return Boolean(this.$route.params.logout);
  }

  get redirect(): string | null {
    if (!this.$route.query.redirect) return null;
    return String(this.$route.query.redirect);
  }

  async mounted() {
    if (this.logout) await logout();
  }

  async signIn(): Promise<void> {
    try {
      this.loading = true;
      await authenticate(this.user);
      if (this.redirect) await this.$router.push({ path: this.redirect });
      else await this.$router.push({ name: "HOME" });
    } catch (err) {
      this.notification = createErrorNotification(err.message);
    } finally {
      this.loading = false;
    }
  }
}
